<template>
  <div class="editor-component">
    <vue-editor
      ref="editor"
      :placeholder="`Max. ${maxLength} characters`"
      v-model="content"
      @text-change="handleInput"
      useCustomImageHandler
      @imageAdded="handleImageAdded"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { COLORS } from '../../constants/types.constant';
import { uploadImg } from '../../services/projects';
import UtilNotify from '@/utils/notify.util';

export default {
  components: { VueEditor },
  props: {
    value: {
      type: String,
      required: false,
    },
    maxLength: {
      type: Number,
      default: 2400,
    },
  },
  data() {
    return {
      content: this.value,
      customToolbar: [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ color: COLORS }, { background: COLORS }],
        ['link', 'image'],
      ],
    };
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
  },
  methods: {
    uploadImg,
    handleImageAdded: async function (file, Editor, cursorLocation, resetUploader) {
      try {
        const formData = new FormData();
        formData.append('image', file);

        const result = await this.uploadImg(formData);
        const url = result.imageUrl; // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url);
        resetUploader();
      } catch (err) {
        UtilNotify.error(this.$notify, err, this.$modal);
        resetUploader();
      }
    },
    handleInput() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength() - 1;
      if (len > this.maxLength) {
        quill.deleteText(this.maxLength, len);
      }
      this.$emit('input', this.content);
      this.$emit('lengthChange', len);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.editor-component {
  ::v-deep .quillWrapper {
    @apply relative;
    @apply rounded-xl;
    background-color: rgba(0, 0, 0, 0.312);
    .ql-toolbar.ql-snow {
      @apply rounded-t-xl;
      background-color: white;
      @apply border-none;
      .ql-picker-options {
        span:first-child {
          background-color: gray !important;
        }
        span:first-child::before {
          content: 'X';
          font-size: 14px;
          color: white;
          @apply relative -top-1.5 left-0.5;
        }
      }
    }
    .ql-container {
      @apply rounded-b-xl;
      .ql-editor.ql-blank::before {
        @apply text-gray-400 font-medium;
        @apply text-xl;
        font-style: normal;
      }
      .ql-editor {
        @apply text-black text-base;
        @apply text-white;
        a {
          text-decoration: none;
          color: inherit;
        }
        a:hover {
          color: rgba(159, 232, 255, 1);
        }
      }
    }
  }
}
</style>
