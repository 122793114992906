import { render, staticRenderFns } from "./promote.page.vue?vue&type=template&id=30abce41&"
import script from "./promote.page.vue?vue&type=script&lang=js&"
export * from "./promote.page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports