<template>
  <div v-if="error" class="error-msg mt-2 text-base">
    {{ error }}
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
