<template>
  <div class="promote-page pb-8">
    <button
      class="text-sm mb-8 cursor-pointer"
      @click="() => $router.push('/manage/projects')"
    >
      &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
    </button>
    <section class="text-3xl mb-20 font-semibold">PR Frame</section>
    <ValidationObserver ref="observer">
      <form
        class="mx-auto"
        @submit.prevent="onSubmitForm"
      >
        <div class="textarea-wrapper">
          <div class="text-xl">English promote message:</div>
          <ValidationProvider
            :rules="{}"
            v-slot="{ errors }"
            name="PR Frame"
          >
            <Editor
              v-model="content"
              :maxLength="2400"
              @lengthChange="onLenChange"
            ></Editor>
            <ErrorMessage
              id="notice"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>
        <div class="textarea-wrapper mt-4">
          <div class="text-xl">Japanese promote message:</div>
          <ValidationProvider
            :rules="{}"
            v-slot="{ errors }"
            name="PR Frame JP"
          >
            <Editor
              v-model="content_jp"
              :maxLength="2400"
              @lengthChange="onLenChangeJp"
            ></Editor>
            <ErrorMessage
              id="notice"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>
        <div class="mx-auto text-center mt-8">
          <button
            class="bg-primary text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl"
            type="submit"
            :disabled="loading"
          >
            {{ $t('PROJECT_SAVE_BTN') }}
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import Editor from '@/components/common/Editor.vue';
import UtilNotify from '@/utils/notify.util';
import { getPRFrame, updatePRFrame } from '@/services/projects';

export default {
  components: {
    ErrorMessage,
    Editor,
  },
  data() {
    return {
      loading: false,
      content: '',
      contentLen: 0,
      content_jp: '',
      contentLenJp: 0,
    };
  },
  async mounted() {
    await this.getNotice();
  },
  methods: {
    async getNotice() {
      this.loading = true;
      const data = await getPRFrame();
      this.content = data.content;
      this.content_jp = data.content_jp;
      this.loading = false;
    },
    async onSubmitForm() {
      try {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) return;
        if (this.loading) return;
        this.loading = true;
        await updatePRFrame(
          this.contentLen ? this.content : '',
          this.contentLenJp ? this.content_jp : ''
        );
        UtilNotify.success(this.$notify, { message: 'NOTIFICATION_SUCCESS_TRANSACTION' });
      } catch (error) {
        UtilNotify.error(this.$notify, error);
      } finally {
        this.loading = false;
      }
    },
    onLenChange(len) {
      this.contentLen = len;
    },
    onLenChangeJp(len) {
      this.contentLenJp = len;
    },
  },
};
</script>

<style></style>
